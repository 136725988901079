.project {
  padding: 0 2.3rem 2.3rem;
  @include mobile {
    padding: 1.6rem;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: -1.2rem;
    @include mobile {
      margin: -0.8rem;
    }
  }

  &__item {
    width: calc(50% - 2.4rem);
    position: relative;
    margin: 1.2rem;
    overflow: hidden;
    cursor: pointer;
    @include mobile {
      width: calc(100% - 1.6rem);
      margin: 0.8rem;
    }

    &--full {
      width: calc(100% - 2.4rem);
      @include mobile {
        width: calc(100% - 1.6rem);
      }
    }

    img {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &:hover {
      .project__img-wrap {
        &::after {
          background-color: rgba($color: $black, $alpha: 0.3);
        }
      }
    }
  }

  &__img-wrap {
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: transparent;
      z-index: 2;
      transition: background-color 0.3s ease-in-out;
    }
  }
}

.project-spacer {
  height: 14.7rem;
  width: 100%;
  @include mobile {
    height: 3.4rem;
  }
}
