.cookie {
  position: fixed;
  z-index: 30;
  max-width: 60rem;
  background-color: $black;
  left: 2.3rem;
  bottom: 2.4rem;
  padding: 1rem 3.6rem;
  border-radius: 10rem;
  opacity: 0;
  transform: translateY(100%);
  transition: $trans;
  @include mobile {
    width: calc(100% - 3.2rem);
    margin: 0 1.6rem;
    left: 0;
    bottom: 1.6rem;
    border-radius: 2rem;
    padding: 1.2rem 1.6rem;
  }

  &.is-shown {
    opacity: 1;
    transform: translateY(0);
  }

  &__inner {
    display: flex;
    @include mobile {
      flex-wrap: wrap;
    }

    .text-container {
      padding-right: 3rem;
      @include mobile {
        padding: 0 0 1.6rem;
      }
    }

    button {
      color: $white;
      @include mobile {
        margin: 0 0 0 auto;
      }
    }
  }
}
