.footer {
  padding: 0 2.3rem 2.4rem;
  @include mobile {
    padding: 0 1.6rem 1.6rem;
  }

  &__inner {
    &--has-link {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include mobile {
        p {
          width: 50%;
        }
      }
    }
  }
}
