.form {
  opacity: 1;
  transition: opacity $trans;
  &.is-loading {
    pointer-events: none !important;
    opacity: 0.5;
  }
  &__foot {
    display: flex;
    justify-content: space-between;
    margin-top: 3.5rem;
    padding-bottom: 3rem;

    @include mobile {
      .form-input-checkbox {
        padding-right: 2rem;
      }
    }
  }
}
