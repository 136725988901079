h1,
.h1 {
  font-size: 3.6rem;
  line-height: 4.4rem;
  font-weight: 300;
  @include mobile {
    font-size: 3.2rem;
    line-height: 4rem;
  }
}

h2,
.h2 {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 300;
  @include mobile {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

h3,
.h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 300;
}

p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  font-weight: 300;
}

small,
.small {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 300;
}

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  margin-top: 3.5rem;
  @include mobile {
    margin-top: 3rem;
  }
}

p + p {
  margin-top: 3rem;
  @include mobile {
    margin-top: 2.5rem;
  }
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
