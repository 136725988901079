.burger {
  z-index: 10;
  width: 3.6rem;
  height: 1.8rem;

  &__line {
    height: 1px;
    width: 100%;
    background-color: currentColor;
    position: relative;
    transition: $trans;

    &:first-child {
      top: 0;
    }

    &:nth-child(2) {
      margin: 0.75rem 0;
    }

    &:last-child {
      bottom: 0;
    }
  }

  &--is-open {
    color: $black;
    .burger__line {
      &:first-child {
        transform: rotate(45deg);
        top: 0.85rem;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        transform: rotate(-45deg);
        bottom: 0.85rem;
      }
    }
  }
}
