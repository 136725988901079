.hero {
  &__slide {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }
  }
}
