.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2.4rem 2.3rem;
  z-index: 100;
  color: $white;

  @include mobile {
    padding: 1.6rem 1.7rem;
    &.menu-is-open {
      .logo svg {
        fill: $black;
      }
    }
  }

  &.on-white-bg {
    color: $black;

    .logo svg {
      fill: $black;
    }
  }

  &--black {
    color: $black;

    .logo svg {
      fill: $black;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }
}
