.link {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;

  &--back {
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 1rem;
      position: relative;
      left: 0;
      transition: 0.15s ease-in-out;
    }

    &:hover {
      svg {
        left: -0.5rem;
      }
    }
  }

  &--to {
    display: inline-flex;
    align-items: center;

    svg {
      margin-left: 1rem;
      position: relative;
      right: 0;
      transition: 0.15s ease-in-out;
    }

    &:hover {
      svg {
        right: -0.5rem;
      }
    }
  }
}
