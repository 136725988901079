.gallery-slider {
  @include mobile {
    height: 100%;
  }
  &__slide {
    img {
      width: 100%;
      height: 100vh;
      object-fit: contain;
      object-position: center;
      @include mobile {
        height: 100%;
      }
    }
  }

  &__next,
  &__prev {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: 50%;
    padding: 0.7rem;
    cursor: pointer;
    @include mobile {
      bottom: 1.7rem;
      top: auto;
    }

    &.swiper-button-disabled {
      opacity: 0.24;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__next {
    right: 1.5rem;
    @include mobile {
      right: 1.7rem;
    }
  }

  &__prev {
    left: 1.5rem;
    @include mobile {
      left: 1.7rem;
    }
  }
}
