@mixin size($w, $h: $w) {
  width: $w;
  height: $h;
}

@mixin selection {
  &::-webkit-selection {
    @content;
  }
  &::-moz-selection {
    @content;
  }
  &::selection {
    @content;
  }
}

@mixin image($file) {
  $url: '../img/#{$file}';
  background-image: url($url);
}

@mixin max($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin min($maxWidth) {
  @media only screen and (min-width: $maxWidth) {
    @content;
  }
}

@mixin full {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin sheet($position: absolute) {
  position: $position;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin background-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin clear {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin abcenter {
  @include translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
}

@mixin inliner {
  @include clear;
  font-size: 0;
  white-space: nowrap;
}

@mixin fontSrc($name) {
  src: url('../fonts/#{$name}.eot');
  src: url('../fonts/#{$name}.eot?#iefix') format('embedded-opentype'),
    url('../fonts/#{$name}.woff2') format('woff2'),
    url('../fonts/#{$name}.woff') format('woff'),
    url('../fonts/#{$name}.ttf') format('truetype');
}

//MEDIA queries

@mixin mobile {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 769px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}
