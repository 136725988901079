.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $white;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;

  &__outer {
    opacity: 0;
    transition: 1s ease-in-out;
  }

  &.is-open {
    display: block;

    .modal__outer {
      transition-delay: 4000s;
      opacity: 1;
    }
  }

  &__inner {
    padding: 0 6rem;
    @include mobile {
      padding: 5.3rem 0;
      height: 100vh;
    }
  }

  &__close {
    height: 3rem;
    width: 3rem;
    padding: 0.7rem;
    cursor: pointer;
    position: absolute;
    top: 1.7rem;
    right: 1.6rem;
    @include mobile {
      right: 2.6rem;
    }

    svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}
