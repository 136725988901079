.textpage {
  padding: 12.3rem 2.3rem 2.4rem;

  &__title {
    margin-bottom: 3.6rem;
  }

  &__inner {
    max-width: 76rem;
  }
}
