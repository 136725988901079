@font-face {
  font-family: "Work Sans";
  @include fontSrc("WorkSans-Regular");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  @include fontSrc("WorkSans-Italic");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  @include fontSrc("WorkSans-Light");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  @include fontSrc("WorkSans-LightItalic");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  @include fontSrc("WorkSans-Bold");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  @include fontSrc("WorkSans-BoldItalic");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
