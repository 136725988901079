.nav {
  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $white;
    color: $black;
    padding: 1.6rem;
    display: flex;
    height: 100%;
    align-items: center;
    transform: translateX(100%);
    transition: 0.3s ease-in-out;

    &--is-open {
      transform: translateX(0);
    }
  }
  &__ul {
    text-align: right;
    @include mobile {
      text-align: left;
    }
  }

  &__li {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
    transition: $trans;

    a {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.2rem;
        width: 0;
        right: 0;
        height: 0.1rem;
        background-color: currentColor;
        transition: width 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      @include desktop {
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }

    &--active {
      a {
        &::after {
          width: 100%;
        }
      }
    }

    @include mobile {
      font-size: 2.8rem;
      margin-bottom: 2rem;
    }

    &--langs {
      margin-top: 1rem;
      @include mobile {
        position: absolute;
        bottom: 0;
        left: 1.6rem;
      }
    }
  }
}
