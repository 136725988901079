.projects {
  padding: 2.3rem;
  @include mobile {
    padding: 1.6rem;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: -1.2rem;
    @include mobile {
      margin: -0.8rem;
    }
  }

  &__item {
    width: calc(50% - 2.4rem);
    padding-bottom: 30%;
    position: relative;
    margin: 1.2rem;
    overflow: hidden;
    @include mobile {
      width: calc(100% - 1.6rem);
      margin: 0.8rem;
      padding-bottom: 56.25%;
    }

    a {
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
        z-index: 2;
        transition: background-color 0.3s ease-in-out;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }

    &:hover {
      a {
        &::after {
          background-color: rgba($color: $black, $alpha: 0.3);
        }
      }
    }
  }
}
