.notfound {
  min-height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  &__inner {
    padding: 13.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    @include mobile {
      padding: 1.6rem;
    }
  }
  &__text {
    text-align: center;

    h1 + p {
      margin-top: 1.6rem;
    }

    .link {
      margin-top: 2.4rem;
      text-decoration: none;
    }
  }
}
