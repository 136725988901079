.text-container {
  &--light {
    color: $white;
  }
  h1 + *,
  h2 + *,
  h3 + *,
  h4 + *,
  h5 + *,
  h6 + *,
  ul + *,
  ol + *,
  p + *,
  table + * {
    margin-top: 3.5rem;
    @include mobile {
      margin-top: 2.5rem;
    }
  }

  p + ul,
  p + ol {
    margin-top: 1.6rem;
  }

  ul {
    li {
      font-size: 1.6rem;
      font-weight: 300;
      position: relative;
      padding-left: 2.8rem;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &::after {
        position: absolute;
        content: '';
        height: 0.1rem;
        width: 1.6rem;
        background-color: $black;
        top: 1rem;
        left: 0;
      }
    }
  }

  ol {
    padding: 0 0 0 2rem;
    li {
      font-size: 1.6rem;
      font-weight: 300;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  table {
    border-spacing: 0px;
    border-collapse: collapse;
    border-radius: 2rem;
    border-style: hidden;
    box-shadow: 0 0 0 1px $black;

    @include mobile {
      display: block;
      width: 100%;
      overflow: auto;
      padding-top: 4.8rem;
      background-image: url(../images/ic_slide.svg);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 5rem 2.4rem;
      box-shadow: none;
      border: none;
      border-radius: 0;
    }

    td,
    th {
      border: 1px solid $black;
      padding: 1.6rem 1.2rem;
      font-size: 1.6rem;
      font-weight: 300;
      text-align: left;
      vertical-align: top;
      color: $black;
    }
  }

  h2,
  .h2 {
    & + * {
      margin-top: 1.5rem;
    }
  }

  h4,
  .h4 {
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 300;
  }

  h5,
  .h5,
  h6,
  .h6 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
  }

  h1:not(:first-child),
  h2:not(:first-child) {
    margin-top: 4.5rem;
  }

  table:not(:first-child) {
    margin-top: 2.4rem;
  }

  a {
    text-decoration: underline;
  }
}
