.about {
  padding: 13.5rem;
  min-height: calc(100vh - 5rem);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mobile {
    padding: 8.2rem 1.6rem 1.6rem;
  }

  &__grid {
    display: flex;
    align-items: center;
    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__left,
  &__right {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }

  &__left {
    padding-right: 12.4rem;
    @include mobile {
      padding: 0;
      order: 2;
      margin-top: 4.8rem;
    }
  }

  &__img-grid {
    display: flex;
    justify-content: space-between;

    img {
      width: calc(50% - 1.2rem);
      height: auto;
    }
  }
}
