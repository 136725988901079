.slogan {
  padding: 6rem 4rem 3.7rem;
  @include mobile {
    padding: 4.8rem 1.6rem 3.7rem;
  }
  &__inner {
    max-width: 70rem;
    margin: auto;
    text-align: center;
  }
}
