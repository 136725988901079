.only-mob {
  @include desktop {
    display: none;
  }
}

.no-mob {
  @include mobile {
    display: none;
  }
}
