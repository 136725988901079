*,
*::before,
*::after {
  box-sizing: border-box !important;
}

* {
  @include selection {
    background-color: $black;
    color: $white;
  }
}

html {
  font-size: 10px;
  @include tablet {
    font-size: calc((100 / 1280) * 10vw);
  }
  @include mobile {
    font-size: 10px;
  }
}

body {
  font-family: $font;
  font-weight: 400;
}

main {
}
