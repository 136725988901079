.form-input {
  position: relative;
  font-size: 1.6rem;

  & + & {
    margin-top: 3.4rem;
  }

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 0;
    bottom: 0;
    left: 0;
    background-color: $black;
    transition: width 0.3s ease-in-out;
  }

  &.focus {
    &::after {
      width: 100%;
    }
  }

  input,
  textarea {
    color: $black;
    width: 100%;
    height: 4rem;
    border: 0;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid $black;
    position: relative;
    z-index: 0;
    font-weight: 300;
  }

  textarea {
    padding: 1.8rem 0 1rem;
    resize: none;
    border-bottom: none;
  }

  &--textarea {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $black;
      bottom: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
    }
  }

  label {
    position: absolute;
    left: 0;
    top: 1.2rem;
    font-size: 1.6rem;
    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
    z-index: 1;
    color: rgba($color: $black, $alpha: 0.5);
    transition: 0.3s ease-in-out;
    cursor: text;
    user-select: none;
    pointer-events: none;
  }

  &.full,
  &.focus {
    label {
      font-size: 1.4rem;
      top: -1.4rem;
    }
  }

  &--has-error {
    &::after {
      background-color: $error;
      width: 100%;
    }

    .form-input__field-message {
      opacity: 1;
    }
  }

  &__field-message {
    position: absolute;
    width: 100%;
    left: 0;
    font-size: 1.2rem;
    color: $error;
    bottom: -2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  // checkbox start
  &-checkbox {
    & + & {
      margin-top: 0;
    }

    &.form-input--has-error {
      &::after {
        display: none;
      }

      label {
        &::before {
          border: 0.1rem solid $error;
        }
      }
    }

    input[data-check] {
      display: none;

      &:not(:checked) + label::after {
        width: 0;
        opacity: 0;
      }

      &:checked + label::before {
        background-color: $black;
      }
    }

    label {
      font-size: 1.6rem;
      display: inline-block;
      cursor: pointer;
      position: relative;
      padding: 0 0 0 2.8rem;
      opacity: 1;
      pointer-events: auto;
      top: 0;
      color: $black;

      a {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 100%;
          height: 1px;
          width: 100%;
          background-color: currentColor;
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        left: 0;
        top: 0.3rem;
      }

      &::before {
        background-color: transparent;
        border: 1px solid $black;
        border-radius: 0.5rem;
        transition: background-color 0.15s ease;
      }

      &::after {
        background-image: url(../images/yes.svg);
        background-repeat: no-repeat;
        background-size: 0.85rem 0.85rem;
        background-position: 0.31rem 0.3rem;
        opacity: 1;
        transition: 0.3s ease-in-out;
      }
    }
  }
}
