.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > div.swiper-pagination-bullets {
  bottom: 3.6rem;
}

span.swiper-pagination-bullet {
  background-color: transparent;
  background: none;
  opacity: 1;
  border: 1px solid $white;
}

span.swiper-pagination-bullet-active {
  background-color: $white;
}
