.langs {
  &__li {
    display: inline-block;
    opacity: 0.48;

    &--active {
      opacity: 1;
    }

    & + & {
      margin-left: 0.8rem;
    }
  }
}
