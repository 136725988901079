.logo {
  z-index: 10;
  svg {
    display: block;
    height: 1.8rem;
    width: 8.7rem;
    fill: $white;
    transition: $trans;
  }

  &--on-white {
    svg {
      fill: $black;
    }
  }
}
