.contacts {
  min-height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  &__inner {
    padding: 13.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    @include mobile {
      padding: 7.2rem 0.6rem 0.6rem;
    }
  }
  &__container {
    max-width: 47rem;
    position: relative;
    overflow: hidden;
    padding: 1rem;
  }
  &__title {
    text-align: center;
    margin-bottom: 3.5rem;
    @include mobile {
      text-align: left;
    }
  }
  &__top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3.5rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    @include mobile {
      flex-direction: column;
    }

    a,
    .a {
      font-size: 1.6rem;
      margin: 0 1.25rem;
      position: relative;
      font-weight: 300;

      @include mobile {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -0.2rem;
        width: 0;
        right: 0;
        height: 0.1rem;
        background-color: currentColor;
        transition: width 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
