.form-response {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 99;
  padding: 2rem;
  transform: translateY(100%);
  transition: 0.3s ease-in-out;

  &.is-visible {
    transform: translateY(0);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .link {
      margin-top: 3rem;
      cursor: pointer;
    }
  }
}
